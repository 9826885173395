/**
 * This file contains table stylings.
 */

md-table-container {
    table {
        th {
            vertical-align: top !important;

            md-input-container.filter {
                display: block;
                padding: 0;
                margin: 5px 0;

                label {
                    bottom: 35px;
                }

                input[type=text] {
                    font-size: 11px;
                    margin-bottom: 0;
                }

                .md-errors-spacer {
                    min-height: 0;
                    min-width: 0;
                }
            }
        }
    }
}
