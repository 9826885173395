/* You can add global styles to this file, and also import other style files */
.xpo-Header {
  background: white !important;
}

// Leave this comment for autocompile.
$panel-default-border-color: #E4E4E4;
$panel-default-header-bg-color: #F1F1F1;
$panel-default-text-color: #333333;

$xpo-blue: #0091EA;
$font-color: #333333;
$hover-bg: #E6E6E6;
$body-bg: #616161;

@import "_animate";

h6 {
  font-weight: 500 !important;
  font-size: 105%;
}

@mixin box-shadow {
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 5px 8px 0 rgba(0, 0, 0, .14), 0 1px 14px 0 rgba(0, 0, 0, .12);
}

// Makes the element a flex element and all children are displayed in a single
// column (top -> down).
.flex-column-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: stretch;
  align-content: stretch;
}

.card-header {
  padding: 10px;
  background-color: $panel-default-header-bg-color;
  border-bottom: 1px solid $panel-default-border-color;
  color: $panel-default-text-color;
  @include box-shadow;

  &.with-margin {
    margin: 15px 0;
  }

  .title {
    margin: 0;
  }

  .sub-title {
    margin: 0;
    font-size: 80%;
  }
}

.modal {
  background: #ffffff;
  border-radius: 2px;
  padding: 10px;
  margin: 0 !important;

  .modal-content {
    box-sizing: border-box;
  }

  .modal-footer {
    box-sizing: border-box;

    & > * {
      float: right;
    }
  }
}

// Makes the element a flex element and all children are displayed in a single
// row (left -> right).
.flex-row-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: stretch;
  align-content: stretch;
}

p.header-text {
  font-size: 150%;
  font-weight: 300;
  color: #333;
  margin: 5px 0 5px 0;
}

.text-overflow {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.error {
  color: red;
}

.width100 {
  width: 100%;
}

.width50 {
  width: 50%;
}

.width33 {
  width: 33%;
}

.width25 {
  width: 25%;
}

.width20 {
  width: 20%;
}

.width16 {
  width: 16.66667%;
}

.width10 {
  width: 10%;
}

.width5 {
  width: 5%;
}

.textCenter {
  text-align: center !important;
}

.textLeft {
  text-align: left !important;
}

.textRight {
  text-align: right !important;
}

.floatLeft {
  float: left;
}

.floatRight {
  float: right;
}

.break {
  clear: both;
}

md-checkbox {
  position: relative !important;
  left: 0 !important;
  opacity: 1 !important;
}

nav {
  @include box-shadow;
}

a {
  cursor: pointer;
}

//table a { color: #555; }
thead tr th {
  vertical-align: top;
}

html, body {
  background-color: $body-bg;
}

.card.with-padding {
  padding: 10px 20px;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

.panel {
  border: 1px solid $panel-default-border-color;
  margin-bottom: 10px;


  .panel-header {
    background-color: $panel-default-header-bg-color;
    border-bottom: 1px solid $panel-default-border-color;
    padding: 10px;
    color: $panel-default-text-color;

    p {
      margin: 0;
    }

    a {
      text-decoration: none;
      color: #555;
    }

    a:hover:not(.btn) {
      text-decoration: underline;
    }

    .row {
      margin-bottom: 0;
    }
  }

  .panel-body {

    &:not(.no-padding) {
      padding: 5px;
    }
  }
}

.no-overflow {
  overflow: hidden;
}

.edi-title {
  display: block;
  font-size: 14px;
}

.edi-subtitle {
  display: block;
  font-size: 12px;
}

.container {
  width: 90% !important;
  max-width: 90% !important;
  margin: 10px auto;
}

/**
 * This is a style class added to the body when the capture service is running.
 */
.capture-mode {
  .container {
    width: 100% !important;
    max-width: 100% !important;
  }

  h4.card-header {
    display: none;
  }

  .card.with-padding {
    padding: 0 !important;
  }

  .edi-title, .edi-subtitle {
    display: inline-block;
    display: inline-block;
  }

  .hide-on-capture {
    display: none !important;
  }

  .show-on-capture {
    display: block !important;
  }
}

/**
 * Card tabs
 */
ul.card-tabs {
  margin: 0;
  padding: 0;

  li {
    display: inline-block;

    a {
      box-sizing: border-box;
      color: $font-color;
      display: block;
      padding: 7px 15px;
      transition: background-color .3s ease;

      &.active {
        border-bottom: 3px solid $xpo-blue;
      }

      &:hover {
        background-color: $hover-bg;
      }
    }
  }
}

/**
 * A tab content container.
 */
.card-tab-content {
  &.with-padding {
    padding: 10px;
  }

  border: 1px solid $hover-bg;
}

.btn-small {
  &.btn-floating {
    width: 20px;
    height: 20px;
    line-height: 20px;

    i {
      font-size: 1.0rem;
      line-height: 20px;
    }
  }

  &.right {
    margin-left: 3px;
  }
}

.clear-fix::after {
  content: '';
  display: block;
  clear: both;
}

.fixed-bottom-row {
  position: fixed;
  bottom: 5px;
  left: 0;
  right: 0;
  z-index: 1;

  .row {
    margin-bottom: 0;
  }
}

.btn.block, .btn-flat.block {
  display: block;
  width: 100%;
  text-align: center;
}

.legend {
  table {
    width: auto;

    td {
      padding: 3px;
    }
  }
}

/**
 * Some of the Material Design CSS library does not jive well with third-party
 * libraries.
 */
.md-datepicker-calendar {
  table {
    width: auto;
  }
}

.nav-wrapper {
  a:hover {
    text-decoration: none !important;
  }
}

/**
 * Navigation
 */
.nav {
  box-sizing: border-box;
  background: #ffffff;
  min-height: 51px;

  .brand-logo {
    display: inline-block;
    color: $font-color;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    font-size: 16px !important;
    padding: 13px 10px;

    .xpo-logo {
      position: relative;
      top: 3px;
      height: 18px;
      width: 120px;
      margin-right: 5px;
    }
  }

  ul.nav-links {
    padding: 0;
    margin: 0;

    li {
      float: left;
      list-style: none;

      a {
        display: inline-block;
        padding: 15px 10px;
        position: relative;
        color: $font-color;
        transition: all .3s ease;

        &:hover {
          background-color: $hover-bg;

          &:before {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 5px;
            content: '';
            background: $xpo-blue;
          }
        }

        &.selected:before {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 5px;
          content: '';
          background: $xpo-blue;
        }
      }
    }
  }
}

/**
 * Progress stuff.
 */
.progress-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999999999;
  background-color: rgba(0, 0, 0, .15);
  transition: all .3s ease;

  &.without-shadow {
    background-color: transparent !important;
  }

  &.progress-hidden {
    display: none;
  }

  &.progress-visible {
    cursor: progress;
  }

  .message-box {
    position: relative;
    top: 0;
    left: 45%;
    width: 10%;
    background: rgba(255, 255, 255, .8);
    padding: 5px;
    border: 1px solid black;
    border-width: 0 1px 1px 1px;
    transition: all .5s ease;

    p {
      margin: 0;
      padding: 0;
      text-align: center;
    }
  }

  &.fadeIn .message-box {
    background: rgba(255, 255, 255, 1);
  }
}

/**
 * Messages.
 */
.message-box {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
  padding: 5px 5px 5px 10px;
  border-left: 10px solid blue;
  background: #FFFFFF;
  margin-bottom: 10px;
  font-size: 90%;

  p {
    padding: 0;
    margin: 0;
  }

  &.info, &.informational {
    border-color: #0091EA;
  }

  &.warn, &.warning {
    border-color: #FFD600;
  }

  &.error {
    border-color: #DD2C00;
  }
}

textarea.tibco-log {
  font-family: monospace;
  width: 100%;
  height: 250px;
  white-space: pre;
}

.tibco-log-label {
  margin-bottom: 5px !important;
  margin-top: 10px !important;
  font-weight: 500;
}

.error-message {
  color: #DD2C00;
}

/**
 * Messages Service
 */
.messages-container {
  position: absolute;
  top: 64px + 10px;
  right: 0;
  z-index: 999999;

  .message-box {
    position: relative;
    margin-bottom: 10px;
    background: #FFFFFF;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
    width: 400px;
    margin-right: 10px;
    padding: 5px 5px 5px 10px;
    border-left: 10px solid blue;

    &.type-inf {
      border-color: #0091EA;
    }

    &.type-wrn {
      border-color: #FFD600;
    }

    &.type-err {
      border-color: #DD2C00;
    }
  }

  .close {
    margin: 0;
    position: absolute;;
    right: 0;
    cursor: pointer;

    &:before {
      @extend .material-icons;
      content: "\e5cd";
      color: rgba(0, 0, 0, .5);
      transition: all .3s ease;
    }

    &:hover {
      &:before {
        color: rgba(0, 0, 0, 1);
      }
    }
  }

  .title {
    margin: 0 0 5px 0;
    font-weight: bold;
  }

  .message {
    margin: 0;
    word-wrap: break-word;
  }
}

.button-panel {
  .md-button {
    position: relative;
    width: 100%;
    padding-left: 20px;

    md-icon {
      position: absolute;
      top: .25em;
      left: .25em;
    }

    &.right-icon {
      padding-left: 0;
      padding-right: 20px;
    }

    &.right-icon md-icon {
      right: .25em;
      left: auto;
    }
  }

  a {
    white-space: nowrap;
    position: relative;
    padding: 0 1rem 0 2.2rem;

    &.right-icon {
      padding: 0 2.2rem 0 1rem;
    }

    .material-icons:not(md-icon) {
      position: absolute;
      top: 0;

      &.left {
        left: 10px;
      }

      &.right {
        right: 10px;
      }
    }
  }
}

/**
 * Modal stuff.
 */
.modal {
  .modal-footer {
    a {
      margin-left: 5px !important;
    }
  }
}

/**
 * Scroll bars.
 */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: rgba(51, 51, 51, 0.1);
  border-radius: 10px;
}

::-webkit-scrollbar-track:hover {
  background: rgba(51, 51, 51, 0.15);
  border-radius: 10px;
  transition: .3s all;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(51, 51, 51, 0.3);
  transition: .3s all;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(51, 51, 51, 0.5);
  transition: .3s all;
}

/**
 * Some extra width classes for the grid system.
 */
@media only screen and (min-width: 601px) {
  .row .col.m1-5 {
    width: 12.5%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
}

.simple-action-container {
  position: relative;

  .simple-action-menu {
    position: absolute;
    bottom: 30px;
    left: -5px;
    z-index: 1;
    padding: 15px;
    min-width: 340px;

    &.ng-show {
      animation: fadeIn 1s;
    }
  }
}

label[title], .fine-print[title], span[title], .has-title, strong[title] {
  &:not([title=""]):not(.no-title):not(.infoText):not(.element) {
    border-bottom: 1px dotted #666666;
  }
}
/*
p.no-padding {
  margin: 0;
  padding: 0;
}

p.fine-print {
  font-size: 80%;
  color: #666666;

  @extend p.no-padding;
}
*/

md-card {
  border-radius: 0 !important;
  @include box-shadow;
}

md-dialog {
  .md-dialog-close-button {
    position: absolute;
    top: 0;
    right: 5px;
    z-index: 1;
  }

  h2.md-dialog-header {
    font-size: 24px;
    font-weight: 400;
    line-height: 3.2rem;
    margin: 0;
  }

  h3 {
    font-size: 18px;
    font-weight: 400;
  }

  md-content {
    background-color: transparent !important;
  }


}

md-tooltip {
  font-size: 14px;

  .md-content.md-show {
    opacity: 1 !important;
    padding: 5px 10px;
    font-weight: 300;
    color: #fff;
  }
}

md-tooltip.tt-multiline .md-content {
  height: auto;
}

party-criteria {
  .row:not(.single-column) {
    .col:first-of-type {
      padding: 0 0.25rem 0 0.75rem;
    }

    .col:last-of-type {
      padding: 0 0.75rem 0 0.25rem;
    }
  }
}

tt {
  font-family: monospace;
  display: inline-block;
  padding-left: 4px;
  padding-right: 4px;
}

@import "_table";


